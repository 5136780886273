<template>

</template>

<script>
export default {
    name: "redirect",
    mounted() {
        let that = this
        // 检查打卡成功
        let u = navigator.userAgent;
        let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
        if(isiOS){
            window.onpageshow = (event) => {
                that.checkRead()
            };
        } else {
            that.checkRead()
        }
    },
    activated () {
        this.checkRead()
    },
    methods:{
        checkRead(){
            let accessUrlJump = localStorage.getItem('accessUrlJump')
            localStorage.removeItem('accessUrlJump')
            if(accessUrlJump){
                document.location.replace(accessUrlJump)
            } else {
                this.$router.back()
            }
        }
    }
}
</script>

<style scoped>

</style>
